import React from 'react';
import './App.css'; // Make sure to include the CSS file for additional styling

function App() {
    return (
        <div className="App">
            <div className="background-image"></div>
            <header className="company-name">Nastech Global Resources Pvt Ltd</header>
            <main className="coming-soon">Coming Soon</main>
        </div>
    );
}

export default App;
